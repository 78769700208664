@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&display=swap');

@layer utilities {
    
.t-h3 {
    font-family: "DM Sans";
    font-weight: 400;
    font-size: 16px;
    line-height:  24px;
}

.t-body-bold {
    font-family: "DM Sans";
    font-weight: 700;
    font-size: 13px;
    line-height:  19.5px;
}

.t-h1 {
    font-family: "DM Sans";
    font-weight: 700;
    font-size: 28px;
    line-height:  38px;
}

.t-body {
    font-family: "DM Sans";
    font-weight: 500;
    font-size: 13px;
    line-height:  19.5px;
}

.t-h2 {
    font-family: "DM Sans";
    font-weight: 700;
    font-size: 20px;
    line-height:  27px;
}

.t-cap {
    font-family: "DM Sans";
    font-weight: 500;
    font-size: 12px;
    line-height:  18px;
}

.t-h4 {
    font-family: "DM Sans";
    font-weight: 600;
    font-size: 14px;
    line-height:  21px;
}

.t-h3-bold {
    font-family: "DM Sans";
    font-weight: 700;
    font-size: 16px;
    line-height:  24px;
}

}
