/* ----------------------------------------------------------------------------------------------------- */
/*  @ Main Tailwind file for injecting utilities.
/* ----------------------------------------------------------------------------------------------------- */
@tailwind utilities;

ul {
    @apply block list-disc my-4 ml-0 mr-0 pl-10;
    unicode-bidi: isolate; /* Custom style not available in Tailwind */
}

ol {
    @apply block list-decimal my-4 ml-0 mr-0 pl-10;
    unicode-bidi: isolate; /* Custom style not available in Tailwind */
}

@import '../ui/figma-styles/generated-typographies.scss';
