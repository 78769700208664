@use '@angular/material' as mat;
@include mat.core();

.shadow-depth-1 {
    backdrop-filter: blur(158px);
}

.bg-white-radial-gradient {
    background-color: rgb(241, 241, 241);
    background-image: radial-gradient(
        50% 50% at 50% 50%,
        rgba(255, 255, 255, 0.7) 0%,
        rgba(255, 255, 255, 0.7) 100%
    );
}
